.check {
  color: #377678 !important;
}

.font-subtitle {
  font-size: 0.9rem !important;
}

.center-line {
  background: linear-gradient(#377678, #377678) no-repeat center/2px 100%;
  display: inline-block;
  padding-top: 1rem;
}

.next-step:hover {
  cursor: pointer;
}

.parent-step:first-child > .center-line {
  line-height: 0;
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.parent-step:last-child > .center-line {
  line-height: 0rem;
  padding-bottom: 0;
  padding-top: 0rem;
}

.parent-step {
  font-size: 14px;
}

.progress-section-btn-text {
  font-size: 11px;
  font-weight: 700;
}

.icon-small {
  transform: translate(0, 3px) scale(0.8);
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.mark-completed-btn-spinner {
  vertical-align: unset !important;
}
