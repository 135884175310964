.section-field-title {
  color: #959595;
  font-size: 12px;
  line-height: 15px;
  padding: 4px 0px;
  width: 160px;
  min-width: 160px;
  margin-right: 8px;
}

.section-field-body {
  font-size: 16px;
  line-height: 19px;
  padding: 2px 0px;
  font-weight: 600;
  overflow-wrap: anywhere;
}
