html {
  background-color: #f4f7f8;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif,
  -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto,
  "Helvetica Neue", Arial,
  "Noto Sans", "Liberation Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.actnBtn {
  background: #00b673 !important;
  border-color: #00b673 !important;
}

.outlineBtn {
  background-color: white !important;
  border-color: #00b673 !important;;
  color: #00b673 !important;;
}
