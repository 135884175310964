.add-employee-container {
  overflow: hidden !important;
  height: inherit;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.add-employee-header {
  font-size: 28px;
  font-weight: 600;
  overflow-wrap: anywhere;
  margin-top: 10px;
}

.employee-already-assigned {
  color: #dc3545;
  font-size: 80%;
}
