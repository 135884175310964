.img-shadow {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  mix-blend-mode: multiply;
}

.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

.login-container {
  overflow: hidden !important;
  height: inherit;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* When the browser is at least 600px and above */
@media screen and (min-width: 576px) {
  img {
    /* Apply some styles */
    height: 100vh;
  }
}
