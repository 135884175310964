.form-ctrl-lbl {
  color: #959595;
  margin-bottom: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
}
