.title-divider {
  height: 1px;
  background-color: #959595;
  width: 100%;
  margin-top: 8px;
}

.title {
  font-family: Montserrat, sans-serif;
  color: grey;
}
