.file-uploader img {
  height: auto;
  max-height: 50vh;
  object-fit: contain;
}

.btn-text {
  font-size: 11px;
  font-weight: 700;
  float: right !important;
  font-family: 'Montserrat', 'sans-serif';
}

.icon {
  color: white;
  margin: 2px;
}

.download-btn {
  float: right;
}
