.padding-0{
    padding-right:0;
    padding-left:0;
}

.number-input {
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    font-weight: bold !important;
    padding-left: 5px;
}

.input-lbl {
    color: #959595;
    margin-bottom: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0;
    text-align: left;
}

.phone-number-row {
    border-bottom: 1px solid #afafaf !important;
}

.phone-number-row-invalid {
    border-bottom: 1px solid #eb0a1e !important;
}

#rfs-btn {
    border: none; 
}

.invalid-tel-input{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}