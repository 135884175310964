th {
  vertical-align: text-top !important;
}

a.nav-item.nav-link.active {
  border-bottom: 4px solid #36bb7b;
  background-color: #e9eff1;
}

a.nav-item.nav-link {
  color: #555;
}

a.nav-item.nav-link:not(.active) > span {
  opacity: 0.5;
}

table {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);
}

table > thead {
  background-color: #f7f7f7;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: white !important;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #f6f6f6 !important;
}

.table-striped tbody tr:hover {
  background-color: #f1f1f1 !important;
}

a.pagination-dropdown {
  color: #212529;
}

a.pagination-dropdown:hover {
  color: #212529;
}

.dropdown-menu {
  min-width: auto !important;
}

.cases-pagination > li > span {
  background-color: inherit !important;
  border: none;
  padding: 0 1rem;
  font-size: large;
  color: #212529;
  opacity: 0.5;
}

.cases-pagination > li > a {
  background-color: inherit;
  border: none;
  padding: 0 1rem;
  font-size: large;
  color: #212529;
}

.cases-pagination > li > a:hover {
  color: #212529;
}

.case-row {
  cursor: pointer;
}

select {
  border-color: #36bb7b;
  border-radius: 5px;
}

input {
  border: solid thin;
  border-radius: 5px;
  border-color: #36bb7b;
}

#dropdown-toggle {
  background-color: white;
  color: grey;
  border-color: #36bb7b;
}

#licenseSearchInput {
  border-color: #36bb7b;
}
