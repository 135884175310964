.navbar {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 1.19rem 2.5rem !important;
}

.app-logo {
  max-height: 23px;
  max-width: 121px;
  cursor: pointer;
}

#search-icon {
  background-color: white;
  padding: 0 0 0 10px;
  border-right: none;
  border-radius: 100px 0 0 100px;
}

#search-input {
  border-left: none;
  border-radius: 0 100px 100px 0;
}

#search-input::placeholder {
  font-weight: 400;
  font-style: italic;
  font-size: 12px;
}

.align-left-25 {
  flex: 0 0 25%;
  margin-right: auto;
  /*Hide search for first MVP*/
  visibility: hidden;
}

.navbar-text {
  color: #555 !important;
  margin: auto 4px;
}
