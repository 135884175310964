.modal-txt {
  font-family: Montserrat;
  font-style: normal;
}

.modal-txt.hdr {
  font-weight: 600;
  font-size: 26px;
  line-height: 29px;
  color: #000000;
  text-align: center;
}

.modal-txt.scndry {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #565656;
  text-align: center;
}
