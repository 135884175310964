.txt-input {
  border: none !important;
  border-bottom: 1px solid #afafaf !important;
  border-radius: 0 !important;
  padding: 0 !important;
  height: auto !important;
  box-shadow: none !important;
  font-weight: bold !important;
}

.txt-input:hover {
  border: none !important;
  border-bottom: 1px solid #555555 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  height: auto !important;
  box-shadow: none !important;
  font-weight: bold !important;
}

.txt-input_invalid {
  border: none !important;
  border-bottom: 1px solid #eb0a1e !important;
  border-radius: 0 !important;
  padding: 0 !important;
  height: auto !important;
  box-shadow: none !important;
  font-weight: bold !important;
}
